import { SubPageMapType } from "@/types/SiteInfo.types";
import Link from "next/link";
import { useState } from "react";
import styles from './NavLink.module.scss';
import { NavLinkType } from "./NavLink.types";

type HamburgerHasSubType = NavLinkType & {
  sub?: SubPageMapType;
}

const NavLink = (props: HamburgerHasSubType) => {
  const { linkTo, linkText, sub, toggleIsHamburgerOpen } = props;
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <Link
        className={`${styles.nav_link} ${isActive ? styles.active : ""} ${sub && styles.nav_link_sub}`}
        href={linkTo === "/" ? "/" : linkTo}
        onClick={
          sub
            ? (event) => {
                // サブメニューがある場合は `active` の切り替え
                event.preventDefault(); // ページ遷移を防ぐ
                setIsActive(!isActive);
              }
            : toggleIsHamburgerOpen // サブメニューがない場合はハンバーガーメニューを閉じる
        }
      >
        {linkText}
      </Link>
      {sub && (
        <ul className={`${styles['sub-links']} ${isActive ? styles.sub_active : styles.sub_hidden}`}>
          {Object.keys(sub).map((key: string, index: number) => (
            <li key={index} className={styles['sub-nav-link']}>
              <Link
                href={sub[key].linkTo}
                onClick={toggleIsHamburgerOpen || undefined}
              >
                {sub[key].title}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default NavLink;