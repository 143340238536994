"use client";

import useToggleHamburger from "../../../hooks/useToggleHamburger.hooks";
import { HamburgerButton } from "../../elements/buttons/HamburgerButton/HamburgerButton";
import styles from "./HamburgerNavigation.module.scss";
import { HamburgerNavigationType } from "./HamburgerNavigation.type";
import HamburgerItems from "./components/HamburgerItems/HamburgerItems";

const HamburgerNavigation = (props: HamburgerNavigationType) => {
  const {linkItems} = props;
  const [isHamburgerOpen, toggleIsHamburgerOpen] = useToggleHamburger(false);

  return (
    <div className={styles.hamburger}>
      <HamburgerButton
        isHamburgerOpen={isHamburgerOpen}
        toggleIsHamburgerOpen={toggleIsHamburgerOpen}
      />
      <div className={styles.hamburger__navigation + (isHamburgerOpen ? ` ${styles.hamburger__navigation__open}` : ` ${styles.hamburger__navigation__close}`)}>
        <div className={styles.hamburgerLink}>
          <a href="/shop/" className={styles.hamburgerLink__tenpo}>
            店舗一覧
          </a>
          <a href="/contact/" className={styles.hamburgerLink__contact}>
            ご予約・お問合せ
          </a>
        </div>
        <HamburgerItems
          linkItems={linkItems}
          toggleIsHamburgerOpen={toggleIsHamburgerOpen}
        />
        <div className={styles.hamburgerLink__recruit}>
          <a href="https://zizaina-recruit.com/" target="_blank">採用情報サイト</a>
        </div>
        <div className={styles.hamburgerLink__icon}>
          <ul className={styles.hamburgerLink__icon__list}>
            <li className={styles.hamburgerLink__icon__list__hoken}>各種保険</li>
            <li className={styles.hamburgerLink__icon__list__card}>カードOK</li>
            <li className={styles.hamburgerLink__icon__list__kids}>キッズルーム</li>
            <li className={styles.hamburgerLink__icon__list__babybed}>ベビーベッド</li>
            <li className={styles.hamburgerLink__icon__list__parking}>大型駐車場</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default HamburgerNavigation;