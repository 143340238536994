import NavLink from "../../../../elements/links/NavLink/NavLink";
import styles from "./HamburgerItems.module.scss";
import { HamburgerNavigationItemsType } from './HamburgerItems.type';

const HamburgerItems = (props: HamburgerNavigationItemsType) => {
  const {linkItems, toggleIsHamburgerOpen} = props;
  return (
    <>
      {linkItems ? (
        <ul className={styles.hamburger_items}>
          {Object.keys(linkItems)
            .filter(key => linkItems[key].isDisplayedOnHamburger ?? true)
            // .sort((a, b) => a.order - b.order) // sort by the order value
            .map((key: string, index: number) => (
              <li key={index}>
                <NavLink
                  linkTo={linkItems[key].linkTo}
                  linkText={linkItems[key].title}
                  sub={linkItems[key].sub && linkItems[key].sub}
                  toggleIsHamburgerOpen={toggleIsHamburgerOpen}
                />
              </li>
            ))}
        </ul>
      ) : null}
    </>
  )
}

export default HamburgerItems;